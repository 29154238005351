@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&display=swap');



#navbar {
    display: flex;
    justify-content: space-between;
    background: #fff;
    color: #000;
    padding: 0.8rem;
    position: sticky;
  }
  #navbar .aaa{
    text-decoration: none;
  }
  #navbar .aaa:visited{
    color: inherit;
  }
  #navbar ul {
    display: flex;
    align-items: center;
    list-style: none;
  }
  #navbar .logo{
    font-size: 1.5rem;
    letter-spacing: 1px;
    font-weight: 900;
    /* border: 1px solid black; */
    padding: 0.1rem 1.5rem;
    display: flex;
    align-items: center;
    /* margin-top: 1px; */
  }
  #navbar ul li a {
    /* border: 1px solid black; */
    color: #000;
    font-size: 1.5rem;
    text-decoration: none;
    padding: 0.1rem 1.5rem;
    color: #0000;
    font-weight: 900;
    background: linear-gradient(90deg,#00BF63 50%,#000 0)  var(--_p,100%)/200% no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    transition: 1s;
  }
  #navbar ul li a:hover {
    --_p: 0%;
  }
  .logo >span{
    color: #00BF63;
  }
  @media (max-width: 640px) {
    #navbar {
      flex-direction: column;
      align-items: center;
    }
  
    #navbar .logo {
      margin-bottom: 0.5rem;
      text-align: center;
    }
  
    #navbar ul {
      padding: 0.5rem;
    }
    #navbar ul li a {
      padding: 0.5rem;
    }
  }
  

