@import url('https://fonts.googleapis.com/css2?family=Noto+Serif:ital,wght@0,100..900;1,100..900&family=Roboto+Slab:wght@100..900&display=swap');

/* Global Styles */
/*
* {
  margin: 0;
  padding: 0;
  font-family: "Noto Serif", serif;
}
*/

/* Job List */
.job-list {
  margin: 1%;
  padding: 0.7rem;
  background-color: #f9f9f9;
}

/* Job Card */
.job-card {
  display: flex;
  justify-content: space-between;
  padding: 1.4rem;
  min-height: 100px;
  align-items: center;
  background-color: #fff;
  border-radius: 30px;
}

.job-card:hover {
  box-shadow: 1px 2px 5px 5px rgba(222, 217, 217, 0.75);
  -webkit-box-shadow: 1px 2px 5px 5px rgba(222, 217, 217, 0.75);
  -moz-box-shadow: 1px 2px 5px 5px rgba(222, 217, 217, 0.75);
  transition: 70ms ease;
  transform: scale(1.02);
}

/* Job Name */
.job-name {
  display: flex;
}

/* Job Profile */
.job-profile {
  width: 60px;
  height: 60px;
  margin: 0.5rem;
  border-radius: 80px;
}

/* Job Detail */
.job-detail {
  padding: 0px 30px;
}

.job-detail > h4 {
  color: #000000;
  font-size: 1.8rem;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.job-detail > h3 {
  color: #000000;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.job-detail > p {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 0.9rem;
}

.job-detail > p {
  font-size: 2rem;
}

/* Job Posted */
.job-posted {
  font-size: 0.8rem;
}

/* Job Posting */
.job-posting {
  text-decoration: none;
  color: white;
  font-weight: bold;
  background-color: #00bf63;
  display: flex;
  gap: 10px;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.job-posting > a:hover {
  transition: 300ms ease-in-out;
  cursor: pointer;
}

.job-posting .apply-link {
  color: rgb(255, 255, 255);
  text-decoration: none;
}
.apply-link{
  display: flex;
  align-items: center;
  justify-content: center;
  
}


/* .job-posting .apply-link .Iconlaunchs:hover {
  color: rgb(0, 10, 40);
} */
.job-posting:hover .apply-link,
.job-posting:hover .Iconlaunchs {
    color: black;
}
/* Category */
.category {
  display: flex;
  gap: 22px;
}

.category p {
  font-size: 0.8rem;
}

/* Job Background */
.job-background {
  background: #00bf63;
  height: 10vh;
}

.job-background .title {
  display: flex;
  align-items: center;
  color: white;
  font-size: 1.2rem;
  padding-top: 3vh;
}

/* Job Section */
.job-section {
  display: flex;
  background: #f7f7fb;
  max-width: 100%;
}

/* Jobs For You */
.jobs-for-you {
  width: 100%;
}

/* Job Page */
.job-page {
  width: 82%;
}

@media (max-width: 855px) {
  .job-page {
    font-size: 10px;
  }

  .job-card {
    display: flex;
  }

  .job-detail > h4 {
    font-size: 11px;
  }

  .job-detail > h3 {
    font-size: 15px;
  }

  .category > p {
    font-size: 10px;
    flex-wrap: wrapS;
  }
}

/* Save Button */
.save-button {
  font-size: 22px;
}

/* Job Button */
.job-button {
  display: flex;
  align-items: center;
  gap: 30px;
}
