.fcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
gap: 10px;
    height: 8vh;
    padding: 0 20px; /* Adjust padding as needed */
}
/* .fcontainer .social-icons a:visited{
    color: rgb(126, 194, 109);
} */
.fcontainer .copyright {
    font-weight: 200;
    color: black;
    font-size: 0.75rem;
}

.social-icons {
    display: flex;
    gap: 10px; /* Adjust gap between icons */
}

.social-icon {
    color: rgb(126, 194, 109);
    font-size: 1rem; /* Adjust icon size as needed */
    /* Add any other styles for the social icons */
}

.social-icons a:hover {
    transition: 0.5s ease;
    color: black;
  }