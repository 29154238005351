@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
/* Updates pushed branch */
/* *{
  margin: 0;
  padding: 0;
  font-family: "Roboto Slab", serif;
} */

.container{
  display: flex;
}

.left{
  flex: 2;
  left: 0;
}

.right{
  flex: 1;
  align-items: center;
  justify-content: center;
}

.banner-img{
  background-color: rgba(209, 246, 209, 0.838);
  height: 83vh;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 95%, 0 0);
  clip-path: polygon(100% 0, 100% 89%, 0 100%, 0 0);
  display: flex;
  align-items: center;
}

.title {
  font-size: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.title > h3 {
  margin-left: 50px;
  color: #000000;
  font-weight: bold;
  font-size: 60px;
  margin-bottom: 20px;
}
.title >h3 > span 
{
  font-weight: bold;
  font-size: 60px;
}
span {
  color: #00BF63;
}
.button {
  text-align: center;
  display: flex;
  flex-direction: column;
}
.button a {
  flex-direction: column;
  text-decoration: none;
  border: 0.5px solid #00BF63;
  border-radius: 10px;
  padding: 20px;
  font-size: xx-large;
  text-transform: uppercase;
  
  color: black;
  font-weight: bold;
}
.button a:hover {
  background: #86ea83;
  transition: 0.5s ease;
  color: black;
  
}
.small-tagline {
  font-size: 20px;
  margin-left: 50px;
}
.small-tagline p {
  color: #404e41;
  top: 50%;
  opacity: 0.7;
  margin-bottom: 30px;
}
.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.social-media > img {
  width: 70px;
}
  @media (max-width: 640px){
    .title{
      font-size: 25px;
    }
  }